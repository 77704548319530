import React from 'react';
import PropTypes from 'prop-types';
import { openPopupWidget } from "react-calendly"
import { useStaticQuery, graphql } from 'gatsby';

import Box from '../../common/components/Box';
import Text from '../../common/components/Text';
import Heading from '../../common/components/Heading';
import Button from '../../common/components/Button';
import Container from '../../common/components/UI/Container';

import NewsletterWrapper, { ContactFormWrapper } from './callToAction.style';

const Newsletter = ({
  sectionWrapper,
  textArea,
  buttonArea,
  buttonStyle,
  title,
  description,
}) => {

  const DATA = useStaticQuery(graphql`
    query {
      dataJson {
        CALLTOACTION {
          sesionTitle
          text
          link {
            path
            label
          }
        }
      }
    }
  `);

  const calendlyData = {
    url: "https://calendly.com/portaregroup/experto",
    prefill: {},
    pageSettings: {},
    utm: {}
  }

  const handleCalendly = () => {
    openPopupWidget(calendlyData)
  };

  const { sesionTitle, text, link } = DATA.dataJson.CALLTOACTION;
  const { path, label } = link;

  return (
    <Box {...sectionWrapper} as="div">
      <Container>
        <NewsletterWrapper>
          <Box {...textArea}>
            <Heading content={sesionTitle} {...title} />
            <Text
              content={text}
              {...description}
            />
          </Box>
          <Box {...buttonArea} onClick={handleCalendly} >
            <ContactFormWrapper>
              <Button {...buttonStyle} title={label} to={path} />
            </ContactFormWrapper>
          </Box>
        </NewsletterWrapper>
      </Container>
    </Box>
  );
};

Newsletter.propTypes = {
  sectionWrapper: PropTypes.object,
  textArea: PropTypes.object,
  buttonArea: PropTypes.object,
  buttonStyle: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
};

Newsletter.defaultProps = {
  sectionWrapper: {},
  textArea: {
    mb: ['10px', '10px', '10px', '0', '0'],
  },
  title: {
    fontSize: ['18px', '18px', '24px', '24px', '28px'],
    fontWeight: 'bolder',
    color: 'white',
    lineHeight: '1.34',
    mb: ['14px', '14px', '14px', '14px', '13px'],
    letterSpacing: '-0.025em',
  },
  description: {
    textAlign: ['center', 'center', 'center', 'left', 'left'],
    fontSize: ['15px', '15px', '18px', '18px', '18px'],
    color: '#fefefe',
    lineHeight: '1.1',
    mb: 0,
  },
  buttonArea: {
    zIndex: 1,
  },
  buttonStyle: {
    type: 'button',
    fontSize: '14px',
    fontWeight: 'bolder',
    pl: '30px',
    pr: '30px',
    colors: 'secondaryPulse',
    marginLeft: 'auto',
  },
};

export default Newsletter;
