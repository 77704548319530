import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import React, { useState, useEffect } from 'react';

import Carousel from './carousel';
import Box from '../../common/components/Box';
import Heading from '../../common/components/Heading';
import Container from '../../common/components/UI/Container';
import { TitleSection, CircleLoader } from '../index.style';

import { CarouselWrapper } from './testimonial.style';

const Testimonial = ({sectionWrapper}) => {
  const DATA = useStaticQuery(graphql`
    query {
      dataJson {
        TESTIMONIAL {
          title
          slogan
          reviews {
            id
            name
            designation
            username
            account_url
            comment
            avatar{
              childImageSharp {
                fixed(width: 105, height: 105) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }
  `);
  const { title, slogan, reviews } = DATA.dataJson.TESTIMONIAL;

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
  }, []);

  return (
    <Box {...sectionWrapper} className="testimonialSlider" id="testimonialSection">
      <Container >
        <TitleSection>
          <Heading as="h5" content={title} />
          <Heading content={slogan} />
        </TitleSection> 
        <CarouselWrapper>
          {loading ? (
            <Carousel data={reviews} />
          ) : (
            <CircleLoader className="alt">
              <div className="circle"></div>
              <div className="circle"></div>
            </CircleLoader>
          )}
        </CarouselWrapper>
      </Container>
    </Box>
  );
};

Testimonial.propTypes = {
  sectionWrapper: PropTypes.object,
};

Testimonial.defaultProps = {
  sectionWrapper: {
    as: 'section',
    pt: '50px',
    pb: ['40px', '80px', '50px', '80px', '80px'],
  }
}

export default Testimonial;
